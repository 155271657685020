import Button from 'components/Button';
import { Maincontent } from 'types/siteAPI';
import useIsWindowSize from 'utils/hooks/useIsWindowSize';

import cssVariables from 'styles/variables.module.scss';
import styles from './ButtonBar.module.scss';

export function ButtonBar({ content }: { content: Maincontent }) {
	const isInWindowSize = useIsWindowSize({
		mediaQuery: cssVariables.mobileL,
	});
	const { settings } = content;
	const fontSize = !isInWindowSize ? '1.4rem' : 'initial';

	return (
		<div className={styles.ButtonBarContainer}>
			{settings?.buttons.map((button) => {
				return (
					<Button
						primary
						href={button.url}
						key={button.name}
						fontSize={fontSize}
					>
						<span className={styles.Name}>{button.name}</span>
						<span className={styles.ShortName}>
							{button.shortname}
						</span>
					</Button>
				);
			})}
		</div>
	);
}

export default ButtonBar;
