import PageComponents from 'app/PageComponents/PageComponents';
import AdSlot, { BannerType } from 'components/AdSlot';
import LocationSearch from 'components/Controls/MyWeather/LocationSearch';
import {
	Bottom,
	Maincontent,
	Maincontentbottom,
	Sidebar,
	Subcontent,
} from 'types/siteAPI';
import useIsWindowSize from 'utils/hooks/useIsWindowSize';

import cssVariables from 'styles/variables.module.scss';
import styles from './home.module.scss';

function Home({
	maincontent,
	maincontentbottom,
	subcontent,
	sidebar,
	bottom,
}: {
	maincontent: Maincontent[];
	maincontentbottom: Maincontentbottom[];
	subcontent: Subcontent[];
	sidebar: Sidebar[];
	bottom: Bottom[];
}) {
	const isInWindow = useIsWindowSize({
		mediaQuery: cssVariables.desktopSMax,
		initialState: true,
	});
	/* 'Bottom' Controls are rendered directly in the grid instead of being wrapped in a container. The reason for this is that Bottom controls can 'bleed'.
	 * Due to this different way of placing we need to dynamically determine the place on which row it should render.
	 * The first 2 rows are used for the other Content slots.
	 */
	let bottomGridStart = 4;
	return (
		<>
			{isInWindow ? (
				<div className={styles.locationSearchContainer}>
					<LocationSearch />
				</div>
			) : null}
			<main
				className={styles.homeLayoutContainer}
				style={{ '--numberOfRows': 10 } as React.CSSProperties}
			>
				<aside className={styles.sidebarContent}>
					{sidebar.map((content) => (
						<PageComponents content={content} key={content.name} />
					))}
					<AdSlot type={BannerType.SkyScraper} id="br-ad-sidebar" />
				</aside>
				<section className={styles.mainContent}>
					{maincontent.map((content) => (
						<PageComponents content={content} key={content.name} />
					))}
					<MobileAdSlot />
				</section>
				<section className={styles.subContent}>
					{subcontent.map((content) => (
						<PageComponents content={content} key={content.name} />
					))}
				</section>
				<section className={styles.mainContentBottom}>
					<DesktopAdSlot />
					{maincontentbottom.map((content) => (
						<PageComponents content={content} key={content.name} />
					))}
				</section>
				<section className={styles.adContainer}>
					<AdSlot
						type={BannerType.RectangleLarge}
						id="br-ad-sidebar-small"
					/>
				</section>
				{bottom.map((content) => {
					bottomGridStart++;
					const styling = shouldBleed(content)
						? `${styles.bleed} ${styles.bottom}`
						: styles.bottom;
					return (
						<div
							className={styling}
							style={
								{
									'--gridRowStart': bottomGridStart,
								} as React.CSSProperties
							}
							key={content.name}
						>
							<PageComponents content={content} />
						</div>
					);
				})}
			</main>
		</>
	);
}

// Some Controls break out the content column and 'bleed' across the width of the page
function shouldBleed(
	content: Maincontent | Maincontentbottom | Subcontent | Sidebar | Bottom
): boolean {
	const contentThatBleeds = ['WeatherForecastBe', 'WeatherForecastNL'];

	if (contentThatBleeds.find((item) => item === content.name)) return true;
	return false;
}

export default Home;

function MobileAdSlot() {
	const isInWindow = useIsWindowSize({
		mediaQuery: cssVariables.tabletMax,
		initialState: true,
	});

	if (isInWindow) {
		return <AdSlot type={BannerType.Rectangle} id="br-ad-header-small" />;
	}

	return null;
}

function DesktopAdSlot() {
	const isInWindow = useIsWindowSize({
		mediaQuery: cssVariables.tablet,
		initialState: false,
	});

	if (isInWindow) {
		return (
			<AdSlot type={BannerType.Rectangle} id="br-ad-home-subcontent" />
		);
	}

	return null;
}
