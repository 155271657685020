import Heading from 'components/Heading/Heading';
import Text from 'components/Text/Text';
import TextLink from 'components/TextLink/TextLink';
import Image from 'next/legacy/image';
import { useQuery } from 'react-query';
import type { AppLocationEnum } from 'types/app';
import type { Bottom, Subcontent } from 'types/siteAPI';
import { WeatherReport } from 'types/weatherreport';
import getWeatherReport from 'utils/getWeatherReport';
import useAppLocation from 'utils/hooks/useAppLocation';
import useIsWindowSize from 'utils/hooks/useIsWindowSize';
import BuienradarImageLoader from 'utils/imageLoader';

import cssVariables from 'styles/variables.module.scss';
import styles from './WeatherForecast.module.scss';

export function WeatherForecastWithData({
	content,
}: {
	content: Bottom | Subcontent;
}) {
	const appLocation = useAppLocation();
	const { data, isError, isLoading } = useQuery('weatherReport', () =>
		getWeatherReport(appLocation)
	);
	const showOnMobile =
		content.show?.some((item) => item === ('mobile' || 'tabletportrait')) ??
		false;
	const showOnDesktop =
		content.show?.some(
			(item) => item === ('desktop' || 'tabletlandscape')
		) ?? false;
	const forecastLink = content?.settings?.forecastLink;

	if (!data || !forecastLink) return null;

	return (
		<WeatherForecast
			appLocation={appLocation}
			data={data}
			isLoading={isLoading}
			isError={isError}
			showOnMobile={showOnMobile}
			showOnDesktop={showOnDesktop}
			forecastLink={forecastLink}
		/>
	);
}

export default function WeatherForecast({
	data,
	isError,
	isLoading,
	appLocation,
	showOnMobile,
	showOnDesktop,
	forecastLink,
}: {
	data: WeatherReport;
	isError: boolean;
	isLoading: boolean;
	appLocation: AppLocationEnum.BE | AppLocationEnum.NL;
	showOnMobile: boolean;
	showOnDesktop: boolean;
	forecastLink: string;
}) {
	const isDesktop = useIsWindowSize({ mediaQuery: cssVariables.desktopS });
	const forecastTitle = `Weerbericht ${
		appLocation === 'NL' ? 'Nederland' : 'België'
	}`;

	/* This Control (WeatherForecast) can placed multiple times on a page but shown on a different location.
	 * In order to only show the 'correct' one we match the media-query against the props coming from HQ.
	 * Based on settings in HQ we know 'when' to render the Control;
	 */
	if (isDesktop && showOnMobile) return null;
	if (!isDesktop && showOnDesktop) return null;
	if (isLoading || isError) return null;

	const authorImage = `https://cdn.buienradar.nl/resources/images/team/200/avatar_${data.creator
		.toLowerCase()
		.trim()
		.replace(' ', '')}.png`;

	return (
		<section className={styles.weatherReportContainer}>
			<Heading variant="h2" margin="0 0 18px 0">
				{forecastTitle}
			</Heading>
			<div>
				<a href={forecastLink} className={styles.weatherReport}>
					<blockquote>
						<Heading variant="h3" margin="0 0 5px 0">
							{data.title}
						</Heading>
						<Text>”{data.description}”</Text>
					</blockquote>
					<div className={styles.weatherReportCreator}>
						<Image
							src={authorImage}
							width="200"
							height="200"
							layout="fixed"
							alt={data.creator}
							loading="lazy"
							loader={BuienradarImageLoader}
						/>
						{data.creator}
					</div>
				</a>
				<TextLink href={forecastLink} appearance="text">
					Lees het volledige weerbericht
				</TextLink>
			</div>
		</section>
	);
}
