import axios from 'redaxios';
import { AppLocationEnum } from 'types/app';
import type { WeatherReport } from 'types/weatherreport';

const getWeatherReport = async (
  appLocation: AppLocationEnum
): Promise<WeatherReport> => {
  const res = await axios.get(
    `https://data.buienradar.nl/1.1/content/weatherreport/${appLocation.toLowerCase()}`
  );
  return res.data;
};

export default getWeatherReport;
