import Button from 'components/Button';

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: {
    message: string;
  };
  resetErrorBoundary: () => void;
}) {
  return (
    <div role="alert">
      <p>Er is iets misgegaan:</p>
      <pre style={{ whiteSpace: 'break-spaces' }}>{error.message}</pre>
      <Button primary onClick={resetErrorBoundary}>
        Probeer opnieuw
      </Button>
    </div>
  );
}
