import ButtonBar from 'components/Controls/ButtonBar/ButtonBar';
import dynamic from 'next/dynamic';
import {
  Maincontent,
  Maincontentbottom,
  Subcontent,
  Sidebar,
  Bottom,
} from 'types/siteAPI';
const CurrentWeatherMobileWithData = dynamic(() =>
  import('components/CurrentWeatherMobile/CurrentWeatherMobile').then(
    (mod) => mod.CurrentWeatherMobileWithData
  )
);
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/ErrorBoundaryFallback/ErrorBoundaryFallback';
import { WeatherForecastWithData } from 'components/Controls/WeatherForecast/WeatherForecast';

const MapContainer = dynamic(
  () => import('components/Controls/MapContainer/MapContainer')
);
const MyWeather = dynamic(
  () => import('components/Controls/MyWeather/MyWeather')
);
const LinkList = dynamic(() => import('components/Controls/LinkList/LinkList'));
const RelevantWeatherThumbnails = dynamic(
  () =>
    import(
      'components/Controls/RelevantWeatherThumbnails/RelevantWeatherThumbnails'
    )
);
const PlaceSunTimesWithData = dynamic(() =>
  import('components/Controls/PlaceSuntimes/PlaceSuntimes').then(
    (mod) => mod.PlaceSuntimesWithData
  )
);
const SocialPictures = dynamic(
  () => import('components/Controls/SocialPictures/SocialPictures')
);

/* PageComponents will dynamically load the required components for a page based on the content.
 * It maps the 'content names' coming from HQ to the internal React component
 */
function PageComponents({
  content,
}: {
  content: Maincontent | Maincontentbottom | Subcontent | Sidebar | Bottom;
}) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => window.location.reload()}
    >
      {(() => {
        switch (content.name) {
          case 'MyWeather':
            return <MyWeather />;
          case 'PlaceSuntimes':
            return <PlaceSunTimesWithData />;
          case 'MapContainer':
            return <MapContainer content={content as Maincontent} />;
          case 'ButtonBar':
            return <ButtonBar content={content as Maincontent} />;
          case 'CurrentWeatherMobile':
            return <CurrentWeatherMobileWithData />;
          case 'WeatherForecastBe':
          case 'WeatherForecastNL':
            return (
              <WeatherForecastWithData
                content={content as Bottom | Subcontent}
              />
            );
          case 'LinkList':
            return <LinkList content={content as Sidebar} />;
          case 'RelevantWeatherThumbnails':
            return <RelevantWeatherThumbnails />;
          case 'SocialPictures':
            return <SocialPictures content={content as Bottom} />;
          default:
            // 'Fallback' render name of component that haven't been mapped yet
            // NOTE: this is going to happen a lot since almost none of component have been built and mapped yet
            return <div style={{ textAlign: 'center' }}>{content.name}</div>;
        }
      })()}
    </ErrorBoundary>
  );
}

export default PageComponents;
