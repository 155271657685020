import SearchBar from 'components/Search/SearchBar';
import SearchResults from 'components/Search/SearchResults';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { GeoLocation } from 'types/locationAPI';
import {
	addFavoriteLocation,
	removeFavoriteLocation,
	updateCurrentLocation,
	useLocationState,
	useLocationUpdater,
} from 'utils/contexts/LocationsContext';
import { getLocationsByQuery } from 'utils/getLocation';
import useDebounce from 'utils/hooks/useDebounce';
import useIsMounted from 'utils/hooks/useIsMounted';

import styles from './LocationSearch.module.scss';
import { searchResultsProps } from './LocationSearch.types';

export default function LocationSearch() {
	const searchResults: searchResultsProps[] = [];
	const locationSearchRef = useRef<HTMLDivElement | null>(null);
	const [showResults, setShowResults] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);
	const locationDispatch = useLocationUpdater();
	const { favorites, currentLocation } = useLocationState();
	const isMounted = useIsMounted();

	const [inputValue, setInputValue] = useState('');

	const { data, isLoading } = useQuery<GeoLocation[], Error>(
		['locations', debouncedSearchTerm],
		() => getLocationsByQuery({ query: debouncedSearchTerm }),
		{
			enabled: Boolean(debouncedSearchTerm),
			refetchOnWindowFocus: false,
		}
	);

	const toggleResults = () => {
		if (!showResults) {
			setShowResults(true);
		}
	};

	const handleClickOutside = useCallback((event: MouseEvent): void => {
		if (
			locationSearchRef.current &&
			!locationSearchRef.current.contains(event.target as Node)
		) {
			setShowResults(false);
		}
	}, []);

	const toggleFavorite = useCallback(
		({ favorite, location }) => {
			if (favorite) {
				locationDispatch(removeFavoriteLocation(location));
			} else {
				locationDispatch(addFavoriteLocation(location));
			}
		},
		[locationDispatch]
	);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [handleClickOutside]);

	if (data && !isLoading) {
		const extendedLocationResults: searchResultsProps = {
			id: 'locations',
			results: data,
			title: 'Plaatsen',
		};
		searchResults.push(extendedLocationResults);
	}
	const favoriteResults = {
		id: 'favorites',
		results: favorites,
		title: 'Favorieten',
	};
	searchResults.push(favoriteResults);

	const resultAction = useCallback(
		(result) => {
			locationDispatch(updateCurrentLocation(result));
			setShowResults(false);
			//remove focus from input field
			const inputField =
				locationSearchRef?.current?.querySelector<HTMLElement>('input');
			if (inputField) {
				inputField.blur();
			}
		},
		[locationDispatch]
	);

	// When the location is a dynamic location we show a small compass icon;
	const isCurrentLocationDynamic = currentLocation?.dynamic;
	const SearchBarStyleOverride =
		isCurrentLocationDynamic && isMounted && !showResults
			? styles.SearchBarDynamicLocation
			: '';

	return (
		<SearchBar
			name="location-search"
			ref={locationSearchRef}
			placeholder=""
			variant="default"
			value={showResults ? inputValue : currentLocation?.name}
			onChange={(event) => {
				setSearchTerm(event.target.value);
				setInputValue(event.target.value);
			}}
			onFocus={() => {
				setInputValue('');
				toggleResults();
			}}
			styleOverride={SearchBarStyleOverride}
		>
			{showResults && searchResults ? (
				<SearchResults
					variant="light"
					searchResults={searchResults}
					favorites={favorites}
					favoriteAction={toggleFavorite}
					resultAction={resultAction}
					resultType="button"
				/>
			) : null}
		</SearchBar>
	);
}
